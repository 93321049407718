// i18next-extract-mark-ns-start qr
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import {Flex} from 'components/Flex';
import React from 'react';
import {DownloadOnAppleStore} from 'components/DownloadOnAppleStore';
import {LanguageEnum} from '../../../locales/types';
import {DownloadOnGooglePlay} from 'components/DownloadOnGooglePlay';
import {AnchorLink} from 'components/AnchorLink';
import {MoneiVideoSection} from 'components/landings/MoneiVideoSection';
import {GradientBox} from 'components/landings/GradientBox';
import {ScreenSizes} from '../../../types/responsive';
import {CompatibleWith} from 'components/monei-pay/CompatibleWith';
import IndexBackground from 'components/monei-pay/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {StyledDiv} from 'components/StyledSx';
import Bold from 'components/Bold';
//Images
import monei_pay_qr_en from 'images/monei_pay_qr_en.png';
import monei_pay_qr_es from 'images/monei_pay_qr_es.png';
import moneiQr from 'images/monei_qr.svg';
import monei_pay_payment_en from 'images/monei_pay_payment_en.png';
import monei_pay_payment_es from 'images/monei_pay_payment_es.png';
import {QrGroup} from 'components/monei-pay/QrGroup';
import {MainTitleContent} from 'components/monei-pay/MainTitleContent';
import {InternalPageLink} from 'components/links/Pages';
import {SupportLink} from 'components/links/Support';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const Background = styled.div`
  position: relative;
  margin-bottom: 150px;

  @media (max-width: ${ScreenSizes.md}) {
    margin-bottom: 75px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding-top: 0;
  }

  &:before {
    display: block;
    content: '';
    height: 49%;
    position: absolute;
    transform: skew(0, -15deg);
    top: 78%;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(40deg, #12e0c2 0%, #aab4e9 90%);
    @media (max-width: ${ScreenSizes.lg}) {
      top: 74%;
      height: 45%;
    }
    @media (max-width: ${ScreenSizes.md}) {
      display: none;
    }
  }
`;

const SectionDescription = styled.p`
  font-weight: 500;
  color: #fafafa;
  font-size: 16px;

  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const MaskedImage = styled.img`
  position: absolute;
  right: 35px;
  bottom: -40px;
  margin-bottom: 0;
  transform: rotate(31deg);
  @media (max-width: ${ScreenSizes.lg}) {
    width: 250px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    width: 210px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    display: none;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const QrCodePayments: React.FC<PageProps> = (props) => {
  const {t, language} = useI18next();
  const scanToPay = language === LanguageEnum.en ? monei_pay_qr_en : monei_pay_qr_es;
  const moneiPayPayment =
    language === LanguageEnum.en ? monei_pay_payment_en : monei_pay_payment_es;
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  return (
    <>
      <SEO
        title={t('In-store QR code Payments')}
        description={t(
          'Accept contactless payments in-store with QR codes. Support mobile payments including Apple Pay, Google Pay, Bizum, and more. Start now ››'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Accept QR code payments with ease</Trans>
              </SectionHeader>
              <Trans parent="p">
                Accept in-store contactless payments and support all the most popular payment
                methods with QR codes using the MONEI Pay app.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
                <CompatibleWith />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
        </Content>
      </IndexBackground>
      <Content sx={{marginTop: {all: '20px'}}}>
        <Section centered>
          <SectionImage
            width={347}
            height={650}
            alt="Monei Pay"
            title="Monei Pay"
            className="hide-on-mobile"
            src={scanToPay}
          />
          <div>
            <SectionHeader underline sx={{marginTop: {all: '0', md: '-20px'}}}>
              <Trans>Grow your business by letting customers pay with QR codes</Trans>
            </SectionHeader>
            <Trans parent="p">
              Avoid expensive and complicated POS terminals and let your customers pay through their
              smarphones. Use the <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink>{' '}
              app to generate digital QR codes — then all shoppers have to do is scan the code,
              choose their preferred payment method, and with the tap of a button the transaction is
              complete.
            </Trans>
            <List>
              <Trans parent="li">No POS terminals necessary</Trans>
              <Trans parent="li">
                <BlogLink slug="contactless-payments-safer-faster-checkout">
                  Contactless payments
                </BlogLink>{' '}
                are faster and more convenient
              </Trans>
              <Trans parent="li">
                Compatible with major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>
              </Trans>
              <Trans parent="li">50% cheaper than your typical POS</Trans>
            </List>
            <Flex>
              <div style={{marginRight: '16px'}}>
                <DownloadOnAppleStore />
              </div>
              <DownloadOnGooglePlay />
            </Flex>
          </div>
        </Section>
      </Content>
      <Background>
        <Content>
          <Section centered style={{padding: '0'}}>
            <div>
              <SectionHeader underline sx={{marginTop: {all: '30px', md: '0'}}}>
                <Trans style={{fontSize: '2rem'}}>
                  Support QR payments via{' '}
                  <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                  and <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                Accept the most popular{' '}
                <InternalPageLink slug="payment-methods">mobile payment methods</InternalPageLink>{' '}
                using MONEI’s{' '}
                <SupportLink slug="/categories/360003757258">QR payment system</SupportLink>. This
                includes Apple Pay, Google Pay, and Bizum payments in Spain.
              </Trans>
              <Trans parent="p">
                You’ll increase customer satisfaction by accepting{' '}
                <BlogLink slug="frictionless-payments">frictionless</BlogLink> in-store or{' '}
                <InternalPageLink slug="monei-pay/qr-code-payment-restaurant">
                  restaurant mobile payments
                </InternalPageLink>
                , while removing the hassle of managing a fleet of POS terminals.
              </Trans>
              <SignUpButton variant="dark">
                <Trans>Try MONEI</Trans>
              </SignUpButton>
            </div>
            <SectionImage
              width={347}
              height={650}
              mobileWidth={370}
              alt="Monei Pay"
              title="Monei Pay"
              className="hide-on-mobile"
              src={moneiPayPayment}
            />
          </Section>
        </Content>
      </Background>

      <Content>
        <Section reverseOnMobile centered sx={{paddingTop: {all: '0', sm: '80px'}}}>
          <QrGroup />
          <div>
            <SectionHeader underline>
              <Trans>Say goodbye to POS terminals and hello to QR codes</Trans>
            </SectionHeader>
            <Trans parent="p">
              Create an account to start generating digital QR codes in seconds on any device. All
              you have to do is enter the payment amount and it will create a QR code on your
              smartphone or tablet. Then your customer can scan to pay with their mobile phone.
            </Trans>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan any QR code to test the payment experience.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Discover MONEI</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content style={{marginTop: '100px'}}>
        <GradientBox>
          <SectionHeader tagName="h3" underline light>
            <Trans>Start accepting QR payments now</Trans>
          </SectionHeader>
          <SectionDescription>
            <Trans parent="p">
              Speed up the checkout process, offer more payment methods, and boost customer
              satisfaction. Streamline the payment process for you and your customers with an
              easy-to-use QR code generator for payments.
            </Trans>
          </SectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              {' '}
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <MaskedImage src={moneiQr} width={300} />
        </GradientBox>
      </Content>
    </>
  );
};

export default QrCodePayments;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "qr"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
